import { Locales } from '@/Locales/locales';

export const downloadFile = (blob: Blob, name: string): void => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(url);
};

export const openInNewTab = (url: string, withProtocol: boolean = true) => {
    try {
        const link_url = new URL(url);

        if (withProtocol && (link_url.protocol == 'http:' || link_url.protocol == 'https:')) {
            window.open(url, '_blank');
        } else {
            window.open(`https://${url}`, '_blank');
        }
    } catch (error) {
        console.error('The provided URL is malformed and can not be created. Provided URL: %s', url);
    }
};

export const browserLocale = (options = {}): string =>{
    const defaultOptions = { countryCodeOnly: false };
    const opt = { ...defaultOptions, ...options };
    const navigatorLocale =
        navigator.languages !== undefined
            ? navigator.languages[0]
            : navigator.language;
    if (!navigatorLocale) {
        return '';
    }
    return opt.countryCodeOnly
        ? navigatorLocale.trim().split(/[-_]/)[0]
        : navigatorLocale.trim();
};
export const getBaseLocale = () =>{
    const baseLocale = browserLocale({ countryCodeOnly: true });
    return Object.values(Locales).includes(baseLocale as unknown as Locales) ? baseLocale : Locales.EN;
};

export const handleImageError = (event: Event | null) => {
    const customEvent = event as Event & { target: HTMLImageElement | null };
    if (customEvent && customEvent.target) {
        customEvent.target.style.display = 'none';
    }
};
