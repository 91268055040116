/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminSiteGroupResource } from '../AfClApiModels';
// @ts-ignore
import { GetSiteGroups200Response } from '../AfClApiModels';
// @ts-ignore
import { Site } from '../AfClApiModels';
/**
 * AdminSiteGroupsApi - axios parameter creator
 * @export
 */
export const AdminSiteGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create site group
         * @param {string} name 
         * @param {string} url 
         * @param {number} publisherId 
         * @param {number} corporationId 
         * @param {number} priority 
         * @param {string} trafficType 
         * @param {File} [logo] 
         * @param {string} [color] 
         * @param {Array<number>} [siteIds] 
         * @param {string} [ga4Id] 
         * @param {string} [gscId] 
         * @param {string} [cloudflareId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSiteGroup: async (name: string, url: string, publisherId: number, corporationId: number, priority: number, trafficType: string, logo?: File, color?: string, siteIds?: Array<number>, ga4Id?: string, gscId?: string, cloudflareId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createSiteGroup', 'name', name)
            // verify required parameter 'url' is not null or undefined
            assertParamExists('createSiteGroup', 'url', url)
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('createSiteGroup', 'publisherId', publisherId)
            // verify required parameter 'corporationId' is not null or undefined
            assertParamExists('createSiteGroup', 'corporationId', corporationId)
            // verify required parameter 'priority' is not null or undefined
            assertParamExists('createSiteGroup', 'priority', priority)
            // verify required parameter 'trafficType' is not null or undefined
            assertParamExists('createSiteGroup', 'trafficType', trafficType)
            const localVarPath = `/v1/admin/sites/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (url !== undefined) { 
                localVarFormParams.append('url', url as any);
            }
    
            if (publisherId !== undefined) { 
                localVarFormParams.append('publisher_id', publisherId as any);
            }
    
            if (corporationId !== undefined) { 
                localVarFormParams.append('corporation_id', corporationId as any);
            }
    
            if (priority !== undefined) { 
                localVarFormParams.append('priority', priority as any);
            }
    
            if (trafficType !== undefined) { 
                localVarFormParams.append('traffic_type', trafficType as any);
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
            if (color !== undefined) { 
                localVarFormParams.append('color', color as any);
            }
                if (siteIds) {
                localVarFormParams.append('site_ids', siteIds.join(COLLECTION_FORMATS.csv));
            }

    
            if (ga4Id !== undefined) { 
                localVarFormParams.append('ga4_id', ga4Id as any);
            }
    
            if (gscId !== undefined) { 
                localVarFormParams.append('gsc_id', gscId as any);
            }
    
            if (cloudflareId !== undefined) { 
                localVarFormParams.append('cloudflare_id', cloudflareId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Site Group
         * @param {number} siteGroupId Numeric ID of the Site Group to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSiteGroup: async (siteGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteGroupId' is not null or undefined
            assertParamExists('deleteSiteGroup', 'siteGroupId', siteGroupId)
            const localVarPath = `/v1/admin/sites/groups/{siteGroupId}`
                .replace(`{${"siteGroupId"}}`, encodeURIComponent(String(siteGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all available site groups with sites.
         * @param {number} siteGroupId Numeric ID of the Site group to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteGroup: async (siteGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteGroupId' is not null or undefined
            assertParamExists('getSiteGroup', 'siteGroupId', siteGroupId)
            const localVarPath = `/v1/admin/sites/groups/{siteGroupId}`
                .replace(`{${"siteGroupId"}}`, encodeURIComponent(String(siteGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all sites for a given Site Group.
         * @summary Get Site Group Sites
         * @param {number} siteGroupId Numeric ID of the Site Group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteGroupSites: async (siteGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteGroupId' is not null or undefined
            assertParamExists('getSiteGroupSites', 'siteGroupId', siteGroupId)
            const localVarPath = `/v1/admin/sites/groups/{siteGroupId}/sites`
                .replace(`{${"siteGroupId"}}`, encodeURIComponent(String(siteGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all available site groups with sites.
         * @param {string} [name] 
         * @param {Array<number>} [siteIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteGroups: async (name?: string, siteIds?: Array<number>, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/sites/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update site group
         * @param {number} siteGroupId Numeric ID of the Site group to update
         * @param {string} [name] 
         * @param {number} [publisherId] 
         * @param {number} [priority] 
         * @param {string} [trafficType] 
         * @param {File} [logo] 
         * @param {string} [color] 
         * @param {number} [corporationId] 
         * @param {Array<number>} [siteIds] 
         * @param {string} [ga4Id] 
         * @param {string} [gscId] 
         * @param {string} [cloudflareId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSiteGroup: async (siteGroupId: number, name?: string, publisherId?: number, priority?: number, trafficType?: string, logo?: File, color?: string, corporationId?: number, siteIds?: Array<number>, ga4Id?: string, gscId?: string, cloudflareId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteGroupId' is not null or undefined
            assertParamExists('updateSiteGroup', 'siteGroupId', siteGroupId)
            const localVarPath = `/v1/admin/sites/groups/{siteGroupId}`
                .replace(`{${"siteGroupId"}}`, encodeURIComponent(String(siteGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (publisherId !== undefined) { 
                localVarFormParams.append('publisher_id', publisherId as any);
            }
    
            if (priority !== undefined) { 
                localVarFormParams.append('priority', priority as any);
            }
    
            if (trafficType !== undefined) { 
                localVarFormParams.append('traffic_type', trafficType as any);
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
            if (color !== undefined) { 
                localVarFormParams.append('color', color as any);
            }
    
            if (corporationId !== undefined) { 
                localVarFormParams.append('corporation_id', corporationId as any);
            }
                if (siteIds) {
                localVarFormParams.append('site_ids', siteIds.join(COLLECTION_FORMATS.csv));
            }

    
            if (ga4Id !== undefined) { 
                localVarFormParams.append('ga4_id', ga4Id as any);
            }
    
            if (gscId !== undefined) { 
                localVarFormParams.append('gsc_id', gscId as any);
            }
    
            if (cloudflareId !== undefined) { 
                localVarFormParams.append('cloudflare_id', cloudflareId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSiteGroupsApi - functional programming interface
 * @export
 */
export const AdminSiteGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSiteGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create site group
         * @param {string} name 
         * @param {string} url 
         * @param {number} publisherId 
         * @param {number} corporationId 
         * @param {number} priority 
         * @param {string} trafficType 
         * @param {File} [logo] 
         * @param {string} [color] 
         * @param {Array<number>} [siteIds] 
         * @param {string} [ga4Id] 
         * @param {string} [gscId] 
         * @param {string} [cloudflareId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSiteGroup(name: string, url: string, publisherId: number, corporationId: number, priority: number, trafficType: string, logo?: File, color?: string, siteIds?: Array<number>, ga4Id?: string, gscId?: string, cloudflareId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSiteGroupResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSiteGroup(name, url, publisherId, corporationId, priority, trafficType, logo, color, siteIds, ga4Id, gscId, cloudflareId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Site Group
         * @param {number} siteGroupId Numeric ID of the Site Group to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSiteGroup(siteGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSiteGroup(siteGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all available site groups with sites.
         * @param {number} siteGroupId Numeric ID of the Site group to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteGroup(siteGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSiteGroupResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteGroup(siteGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all sites for a given Site Group.
         * @summary Get Site Group Sites
         * @param {number} siteGroupId Numeric ID of the Site Group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteGroupSites(siteGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Site>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteGroupSites(siteGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all available site groups with sites.
         * @param {string} [name] 
         * @param {Array<number>} [siteIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteGroups(name?: string, siteIds?: Array<number>, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSiteGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteGroups(name, siteIds, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update site group
         * @param {number} siteGroupId Numeric ID of the Site group to update
         * @param {string} [name] 
         * @param {number} [publisherId] 
         * @param {number} [priority] 
         * @param {string} [trafficType] 
         * @param {File} [logo] 
         * @param {string} [color] 
         * @param {number} [corporationId] 
         * @param {Array<number>} [siteIds] 
         * @param {string} [ga4Id] 
         * @param {string} [gscId] 
         * @param {string} [cloudflareId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSiteGroup(siteGroupId: number, name?: string, publisherId?: number, priority?: number, trafficType?: string, logo?: File, color?: string, corporationId?: number, siteIds?: Array<number>, ga4Id?: string, gscId?: string, cloudflareId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSiteGroupResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSiteGroup(siteGroupId, name, publisherId, priority, trafficType, logo, color, corporationId, siteIds, ga4Id, gscId, cloudflareId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSiteGroupsApi - factory interface
 * @export
 */
export const AdminSiteGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSiteGroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create site group
         * @param {AdminSiteGroupsApiCreateSiteGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSiteGroup(requestParameters: AdminSiteGroupsApiCreateSiteGroupRequest, options?: AxiosRequestConfig): AxiosPromise<AdminSiteGroupResource> {
            return localVarFp.createSiteGroup(requestParameters.name, requestParameters.url, requestParameters.publisherId, requestParameters.corporationId, requestParameters.priority, requestParameters.trafficType, requestParameters.logo, requestParameters.color, requestParameters.siteIds, requestParameters.ga4Id, requestParameters.gscId, requestParameters.cloudflareId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Site Group
         * @param {AdminSiteGroupsApiDeleteSiteGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSiteGroup(requestParameters: AdminSiteGroupsApiDeleteSiteGroupRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSiteGroup(requestParameters.siteGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all available site groups with sites.
         * @param {AdminSiteGroupsApiGetSiteGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteGroup(requestParameters: AdminSiteGroupsApiGetSiteGroupRequest, options?: AxiosRequestConfig): AxiosPromise<AdminSiteGroupResource> {
            return localVarFp.getSiteGroup(requestParameters.siteGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all sites for a given Site Group.
         * @summary Get Site Group Sites
         * @param {AdminSiteGroupsApiGetSiteGroupSitesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteGroupSites(requestParameters: AdminSiteGroupsApiGetSiteGroupSitesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Site>> {
            return localVarFp.getSiteGroupSites(requestParameters.siteGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all available site groups with sites.
         * @param {AdminSiteGroupsApiGetSiteGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteGroups(requestParameters: AdminSiteGroupsApiGetSiteGroupsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetSiteGroups200Response> {
            return localVarFp.getSiteGroups(requestParameters.name, requestParameters.siteIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update site group
         * @param {AdminSiteGroupsApiUpdateSiteGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSiteGroup(requestParameters: AdminSiteGroupsApiUpdateSiteGroupRequest, options?: AxiosRequestConfig): AxiosPromise<AdminSiteGroupResource> {
            return localVarFp.updateSiteGroup(requestParameters.siteGroupId, requestParameters.name, requestParameters.publisherId, requestParameters.priority, requestParameters.trafficType, requestParameters.logo, requestParameters.color, requestParameters.corporationId, requestParameters.siteIds, requestParameters.ga4Id, requestParameters.gscId, requestParameters.cloudflareId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSiteGroup operation in AdminSiteGroupsApi.
 * @export
 * @interface AdminSiteGroupsApiCreateSiteGroupRequest
 */
export interface AdminSiteGroupsApiCreateSiteGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly url: string

    /**
     * 
     * @type {number}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly publisherId: number

    /**
     * 
     * @type {number}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly corporationId: number

    /**
     * 
     * @type {number}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly priority: number

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly trafficType: string

    /**
     * 
     * @type {File}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly logo?: File

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly color?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly ga4Id?: string

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly gscId?: string

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiCreateSiteGroup
     */
    readonly cloudflareId?: string
}

/**
 * Request parameters for deleteSiteGroup operation in AdminSiteGroupsApi.
 * @export
 * @interface AdminSiteGroupsApiDeleteSiteGroupRequest
 */
export interface AdminSiteGroupsApiDeleteSiteGroupRequest {
    /**
     * Numeric ID of the Site Group to delete
     * @type {number}
     * @memberof AdminSiteGroupsApiDeleteSiteGroup
     */
    readonly siteGroupId: number
}

/**
 * Request parameters for getSiteGroup operation in AdminSiteGroupsApi.
 * @export
 * @interface AdminSiteGroupsApiGetSiteGroupRequest
 */
export interface AdminSiteGroupsApiGetSiteGroupRequest {
    /**
     * Numeric ID of the Site group to find
     * @type {number}
     * @memberof AdminSiteGroupsApiGetSiteGroup
     */
    readonly siteGroupId: number
}

/**
 * Request parameters for getSiteGroupSites operation in AdminSiteGroupsApi.
 * @export
 * @interface AdminSiteGroupsApiGetSiteGroupSitesRequest
 */
export interface AdminSiteGroupsApiGetSiteGroupSitesRequest {
    /**
     * Numeric ID of the Site Group
     * @type {number}
     * @memberof AdminSiteGroupsApiGetSiteGroupSites
     */
    readonly siteGroupId: number
}

/**
 * Request parameters for getSiteGroups operation in AdminSiteGroupsApi.
 * @export
 * @interface AdminSiteGroupsApiGetSiteGroupsRequest
 */
export interface AdminSiteGroupsApiGetSiteGroupsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiGetSiteGroups
     */
    readonly name?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSiteGroupsApiGetSiteGroups
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminSiteGroupsApiGetSiteGroups
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSiteGroupsApiGetSiteGroups
     */
    readonly perPage?: number
}

/**
 * Request parameters for updateSiteGroup operation in AdminSiteGroupsApi.
 * @export
 * @interface AdminSiteGroupsApiUpdateSiteGroupRequest
 */
export interface AdminSiteGroupsApiUpdateSiteGroupRequest {
    /**
     * Numeric ID of the Site group to update
     * @type {number}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly siteGroupId: number

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly publisherId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly priority?: number

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly trafficType?: string

    /**
     * 
     * @type {File}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly logo?: File

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly color?: string

    /**
     * 
     * @type {number}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly corporationId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly ga4Id?: string

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly gscId?: string

    /**
     * 
     * @type {string}
     * @memberof AdminSiteGroupsApiUpdateSiteGroup
     */
    readonly cloudflareId?: string
}

/**
 * AdminSiteGroupsApi - object-oriented interface
 * @export
 * @class AdminSiteGroupsApi
 * @extends {BaseAPI}
 */
export class AdminSiteGroupsApi extends BaseAPI {
    /**
     * 
     * @summary Create site group
     * @param {AdminSiteGroupsApiCreateSiteGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSiteGroupsApi
     */
    public createSiteGroup(requestParameters: AdminSiteGroupsApiCreateSiteGroupRequest, options?: AxiosRequestConfig) {
        return AdminSiteGroupsApiFp(this.configuration).createSiteGroup(requestParameters.name, requestParameters.url, requestParameters.publisherId, requestParameters.corporationId, requestParameters.priority, requestParameters.trafficType, requestParameters.logo, requestParameters.color, requestParameters.siteIds, requestParameters.ga4Id, requestParameters.gscId, requestParameters.cloudflareId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Site Group
     * @param {AdminSiteGroupsApiDeleteSiteGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSiteGroupsApi
     */
    public deleteSiteGroup(requestParameters: AdminSiteGroupsApiDeleteSiteGroupRequest, options?: AxiosRequestConfig) {
        return AdminSiteGroupsApiFp(this.configuration).deleteSiteGroup(requestParameters.siteGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all available site groups with sites.
     * @param {AdminSiteGroupsApiGetSiteGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSiteGroupsApi
     */
    public getSiteGroup(requestParameters: AdminSiteGroupsApiGetSiteGroupRequest, options?: AxiosRequestConfig) {
        return AdminSiteGroupsApiFp(this.configuration).getSiteGroup(requestParameters.siteGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all sites for a given Site Group.
     * @summary Get Site Group Sites
     * @param {AdminSiteGroupsApiGetSiteGroupSitesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSiteGroupsApi
     */
    public getSiteGroupSites(requestParameters: AdminSiteGroupsApiGetSiteGroupSitesRequest, options?: AxiosRequestConfig) {
        return AdminSiteGroupsApiFp(this.configuration).getSiteGroupSites(requestParameters.siteGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all available site groups with sites.
     * @param {AdminSiteGroupsApiGetSiteGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSiteGroupsApi
     */
    public getSiteGroups(requestParameters: AdminSiteGroupsApiGetSiteGroupsRequest = {}, options?: AxiosRequestConfig) {
        return AdminSiteGroupsApiFp(this.configuration).getSiteGroups(requestParameters.name, requestParameters.siteIds, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update site group
     * @param {AdminSiteGroupsApiUpdateSiteGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSiteGroupsApi
     */
    public updateSiteGroup(requestParameters: AdminSiteGroupsApiUpdateSiteGroupRequest, options?: AxiosRequestConfig) {
        return AdminSiteGroupsApiFp(this.configuration).updateSiteGroup(requestParameters.siteGroupId, requestParameters.name, requestParameters.publisherId, requestParameters.priority, requestParameters.trafficType, requestParameters.logo, requestParameters.color, requestParameters.corporationId, requestParameters.siteIds, requestParameters.ga4Id, requestParameters.gscId, requestParameters.cloudflareId, options).then((request) => request(this.axios, this.basePath));
    }
}
