import { Route } from 'vue-lite-route';
import { MiddlewareGroups } from '@/Router/Middleware/MiddlewareGroups';

const DashboardPage = () => import('@/Pages/Shared/DashboardPage.vue');
const ProgramsPage = () => import('@/Pages/Advertiser/ProgramsPage.vue');
const ReportsPage = () => import('@/Pages/Advertiser/ReportsPage.vue');
const PublisherCompliancePage = () => import('@/Pages/Advertiser/PublisherCompliancePage.vue');

export const RegisterAdvertiserRoutes = () => {
    Route.group({ prefix: '/advertisers/:accountId', name: 'advertiser', middleware: MiddlewareGroups.advertiser }, () => {
        Route.add('/dashboard', { component: DashboardPage }).name('.dashboard');
        Route.add('/programs', { component: ProgramsPage }).name('.programs');
        Route.add('/reports', { component: ReportsPage }).name('.reports');
        Route.add('/publisher-compliance', { component: PublisherCompliancePage }).name('.publisher_compliance');
    });
};
