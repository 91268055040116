import { MiddlewareFunction } from 'vue-lite-route/src/Contracts/Middleware/MiddlewareFunction';
import { UserType } from '@/Enums/UserType';
import SetPageTitle from '@/Utils/SetPageTitle';

export const getPageTitleKey = (pageName: string = '') => `pages.titles["${pageName}"]`;

export const PageTitleHandler = (
    (type: UserType ): MiddlewareFunction => (
        async (context) => {
            const { translate, hasTranslation } = window as Window & typeof globalThis & { 
                translate: (key: string) => string,
                hasTranslation: (key: string) => boolean
            };
            const pageTranslationKey: string = getPageTitleKey(context.to?.name?.toString() ?? '');
            if(hasTranslation(pageTranslationKey)) {
                SetPageTitle(translate(pageTranslationKey), type);
            }

            return context.next();
        }
    )
);
