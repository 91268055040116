<template>
    <p
        class="mt-2 text-sm text-red-600"
    >
        <slot />
    </p>
</template>

<script setup lang="ts">
</script>
