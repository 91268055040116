/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminPublisherManagerRuleResource } from '../AfClApiModels';
// @ts-ignore
import { GetAdminPublisherManagerRules200Response } from '../AfClApiModels';
// @ts-ignore
import { StorePublisherManagerRuleRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdatePublisherManagerRuleRequest } from '../AfClApiModels';
/**
 * AdminPublisherManagerRulesApi - axios parameter creator
 * @export
 */
export const AdminPublisherManagerRulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create PublisherManagerRule
         * @param {StorePublisherManagerRuleRequest} [storePublisherManagerRuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherManagerRule: async (storePublisherManagerRuleRequest?: StorePublisherManagerRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/publisher-manager-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storePublisherManagerRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete PublisherManagerRule
         * @param {number} publisherManagerRuleId Numeric ID of the PublisherManagerRule to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisherManagerRule: async (publisherManagerRuleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherManagerRuleId' is not null or undefined
            assertParamExists('deletePublisherManagerRule', 'publisherManagerRuleId', publisherManagerRuleId)
            const localVarPath = `/v1/admin/publisher-manager-rules/{publisherManagerRuleId}`
                .replace(`{${"publisherManagerRuleId"}}`, encodeURIComponent(String(publisherManagerRuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get PublisherManagerRules
         * @param {number} page 
         * @param {number} perPage 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {'PAID' | 'ORGANIC' | 'INTEGRATION'} [trafficType] 
         * @param {'Publisher' | 'LinkRequest'} [type] 
         * @param {Array<number>} [publisherManagerIds] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPublisherManagerRules: async (page: number, perPage: number, regionIds?: Array<number>, verticalIds?: Array<number>, trafficType?: 'PAID' | 'ORGANIC' | 'INTEGRATION', type?: 'Publisher' | 'LinkRequest', publisherManagerIds?: Array<number>, sortBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getAdminPublisherManagerRules', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('getAdminPublisherManagerRules', 'perPage', perPage)
            const localVarPath = `/v1/admin/publisher-manager-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (trafficType !== undefined) {
                localVarQueryParameter['traffic_type'] = trafficType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (publisherManagerIds) {
                localVarQueryParameter['publisher_manager_ids[]'] = publisherManagerIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update PublisherManagerRule
         * @param {number} publisherManagerRuleId Numeric ID of the PublisherManagerRule to update
         * @param {UpdatePublisherManagerRuleRequest} [updatePublisherManagerRuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherManagerRule: async (publisherManagerRuleId: number, updatePublisherManagerRuleRequest?: UpdatePublisherManagerRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherManagerRuleId' is not null or undefined
            assertParamExists('updatePublisherManagerRule', 'publisherManagerRuleId', publisherManagerRuleId)
            const localVarPath = `/v1/admin/publisher-manager-rules/{publisherManagerRuleId}`
                .replace(`{${"publisherManagerRuleId"}}`, encodeURIComponent(String(publisherManagerRuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePublisherManagerRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPublisherManagerRulesApi - functional programming interface
 * @export
 */
export const AdminPublisherManagerRulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPublisherManagerRulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create PublisherManagerRule
         * @param {StorePublisherManagerRuleRequest} [storePublisherManagerRuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPublisherManagerRule(storePublisherManagerRuleRequest?: StorePublisherManagerRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPublisherManagerRuleResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPublisherManagerRule(storePublisherManagerRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete PublisherManagerRule
         * @param {number} publisherManagerRuleId Numeric ID of the PublisherManagerRule to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePublisherManagerRule(publisherManagerRuleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePublisherManagerRule(publisherManagerRuleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get PublisherManagerRules
         * @param {number} page 
         * @param {number} perPage 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {'PAID' | 'ORGANIC' | 'INTEGRATION'} [trafficType] 
         * @param {'Publisher' | 'LinkRequest'} [type] 
         * @param {Array<number>} [publisherManagerIds] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminPublisherManagerRules(page: number, perPage: number, regionIds?: Array<number>, verticalIds?: Array<number>, trafficType?: 'PAID' | 'ORGANIC' | 'INTEGRATION', type?: 'Publisher' | 'LinkRequest', publisherManagerIds?: Array<number>, sortBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminPublisherManagerRules200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminPublisherManagerRules(page, perPage, regionIds, verticalIds, trafficType, type, publisherManagerIds, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update PublisherManagerRule
         * @param {number} publisherManagerRuleId Numeric ID of the PublisherManagerRule to update
         * @param {UpdatePublisherManagerRuleRequest} [updatePublisherManagerRuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePublisherManagerRule(publisherManagerRuleId: number, updatePublisherManagerRuleRequest?: UpdatePublisherManagerRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPublisherManagerRuleResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePublisherManagerRule(publisherManagerRuleId, updatePublisherManagerRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPublisherManagerRulesApi - factory interface
 * @export
 */
export const AdminPublisherManagerRulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPublisherManagerRulesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create PublisherManagerRule
         * @param {AdminPublisherManagerRulesApiCreatePublisherManagerRuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisherManagerRule(requestParameters: AdminPublisherManagerRulesApiCreatePublisherManagerRuleRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminPublisherManagerRuleResource> {
            return localVarFp.createPublisherManagerRule(requestParameters.storePublisherManagerRuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete PublisherManagerRule
         * @param {AdminPublisherManagerRulesApiDeletePublisherManagerRuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisherManagerRule(requestParameters: AdminPublisherManagerRulesApiDeletePublisherManagerRuleRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePublisherManagerRule(requestParameters.publisherManagerRuleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get PublisherManagerRules
         * @param {AdminPublisherManagerRulesApiGetAdminPublisherManagerRulesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPublisherManagerRules(requestParameters: AdminPublisherManagerRulesApiGetAdminPublisherManagerRulesRequest, options?: AxiosRequestConfig): AxiosPromise<GetAdminPublisherManagerRules200Response> {
            return localVarFp.getAdminPublisherManagerRules(requestParameters.page, requestParameters.perPage, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.trafficType, requestParameters.type, requestParameters.publisherManagerIds, requestParameters.sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update PublisherManagerRule
         * @param {AdminPublisherManagerRulesApiUpdatePublisherManagerRuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisherManagerRule(requestParameters: AdminPublisherManagerRulesApiUpdatePublisherManagerRuleRequest, options?: AxiosRequestConfig): AxiosPromise<AdminPublisherManagerRuleResource> {
            return localVarFp.updatePublisherManagerRule(requestParameters.publisherManagerRuleId, requestParameters.updatePublisherManagerRuleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPublisherManagerRule operation in AdminPublisherManagerRulesApi.
 * @export
 * @interface AdminPublisherManagerRulesApiCreatePublisherManagerRuleRequest
 */
export interface AdminPublisherManagerRulesApiCreatePublisherManagerRuleRequest {
    /**
     * 
     * @type {StorePublisherManagerRuleRequest}
     * @memberof AdminPublisherManagerRulesApiCreatePublisherManagerRule
     */
    readonly storePublisherManagerRuleRequest?: StorePublisherManagerRuleRequest
}

/**
 * Request parameters for deletePublisherManagerRule operation in AdminPublisherManagerRulesApi.
 * @export
 * @interface AdminPublisherManagerRulesApiDeletePublisherManagerRuleRequest
 */
export interface AdminPublisherManagerRulesApiDeletePublisherManagerRuleRequest {
    /**
     * Numeric ID of the PublisherManagerRule to delete
     * @type {number}
     * @memberof AdminPublisherManagerRulesApiDeletePublisherManagerRule
     */
    readonly publisherManagerRuleId: number
}

/**
 * Request parameters for getAdminPublisherManagerRules operation in AdminPublisherManagerRulesApi.
 * @export
 * @interface AdminPublisherManagerRulesApiGetAdminPublisherManagerRulesRequest
 */
export interface AdminPublisherManagerRulesApiGetAdminPublisherManagerRulesRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminPublisherManagerRulesApiGetAdminPublisherManagerRules
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof AdminPublisherManagerRulesApiGetAdminPublisherManagerRules
     */
    readonly perPage: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminPublisherManagerRulesApiGetAdminPublisherManagerRules
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminPublisherManagerRulesApiGetAdminPublisherManagerRules
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {'PAID' | 'ORGANIC' | 'INTEGRATION'}
     * @memberof AdminPublisherManagerRulesApiGetAdminPublisherManagerRules
     */
    readonly trafficType?: 'PAID' | 'ORGANIC' | 'INTEGRATION'

    /**
     * 
     * @type {'Publisher' | 'LinkRequest'}
     * @memberof AdminPublisherManagerRulesApiGetAdminPublisherManagerRules
     */
    readonly type?: 'Publisher' | 'LinkRequest'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminPublisherManagerRulesApiGetAdminPublisherManagerRules
     */
    readonly publisherManagerIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdminPublisherManagerRulesApiGetAdminPublisherManagerRules
     */
    readonly sortBy?: string
}

/**
 * Request parameters for updatePublisherManagerRule operation in AdminPublisherManagerRulesApi.
 * @export
 * @interface AdminPublisherManagerRulesApiUpdatePublisherManagerRuleRequest
 */
export interface AdminPublisherManagerRulesApiUpdatePublisherManagerRuleRequest {
    /**
     * Numeric ID of the PublisherManagerRule to update
     * @type {number}
     * @memberof AdminPublisherManagerRulesApiUpdatePublisherManagerRule
     */
    readonly publisherManagerRuleId: number

    /**
     * 
     * @type {UpdatePublisherManagerRuleRequest}
     * @memberof AdminPublisherManagerRulesApiUpdatePublisherManagerRule
     */
    readonly updatePublisherManagerRuleRequest?: UpdatePublisherManagerRuleRequest
}

/**
 * AdminPublisherManagerRulesApi - object-oriented interface
 * @export
 * @class AdminPublisherManagerRulesApi
 * @extends {BaseAPI}
 */
export class AdminPublisherManagerRulesApi extends BaseAPI {
    /**
     * 
     * @summary Create PublisherManagerRule
     * @param {AdminPublisherManagerRulesApiCreatePublisherManagerRuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherManagerRulesApi
     */
    public createPublisherManagerRule(requestParameters: AdminPublisherManagerRulesApiCreatePublisherManagerRuleRequest = {}, options?: AxiosRequestConfig) {
        return AdminPublisherManagerRulesApiFp(this.configuration).createPublisherManagerRule(requestParameters.storePublisherManagerRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete PublisherManagerRule
     * @param {AdminPublisherManagerRulesApiDeletePublisherManagerRuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherManagerRulesApi
     */
    public deletePublisherManagerRule(requestParameters: AdminPublisherManagerRulesApiDeletePublisherManagerRuleRequest, options?: AxiosRequestConfig) {
        return AdminPublisherManagerRulesApiFp(this.configuration).deletePublisherManagerRule(requestParameters.publisherManagerRuleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get PublisherManagerRules
     * @param {AdminPublisherManagerRulesApiGetAdminPublisherManagerRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherManagerRulesApi
     */
    public getAdminPublisherManagerRules(requestParameters: AdminPublisherManagerRulesApiGetAdminPublisherManagerRulesRequest, options?: AxiosRequestConfig) {
        return AdminPublisherManagerRulesApiFp(this.configuration).getAdminPublisherManagerRules(requestParameters.page, requestParameters.perPage, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.trafficType, requestParameters.type, requestParameters.publisherManagerIds, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update PublisherManagerRule
     * @param {AdminPublisherManagerRulesApiUpdatePublisherManagerRuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPublisherManagerRulesApi
     */
    public updatePublisherManagerRule(requestParameters: AdminPublisherManagerRulesApiUpdatePublisherManagerRuleRequest, options?: AxiosRequestConfig) {
        return AdminPublisherManagerRulesApiFp(this.configuration).updatePublisherManagerRule(requestParameters.publisherManagerRuleId, requestParameters.updatePublisherManagerRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
