import { Currency, CurrencyDisplayType } from '@/Enums/Currency';
import { Currency as CurrencyResource } from '@/stub';

export interface ICurrencyOptions {
    displayType: string,
    defaultValue: string | number,
    alwaysWithCurrency: boolean,
    addZero: boolean,
    hideSymbol: boolean
}

function withCurrency(value: string | number, currency?: string | CurrencyResource | null, options?: Partial<ICurrencyOptions>): string {
    let currencyCode: string|undefined = (typeof currency === 'string')
        ? currency
        : currency?.code;

    const defaultOptions: ICurrencyOptions = {
        displayType: CurrencyDisplayType.SYMBOL,
        defaultValue: '0',
        alwaysWithCurrency: false,
        addZero: true,
        hideSymbol: false
    };

    let currencyOptions: ICurrencyOptions;
    if (options === undefined) {
        currencyOptions = { ...defaultOptions };
    } else {
        Object.keys(options).forEach(key => options[key] === undefined && delete options[key]);
        currencyOptions = { ...defaultOptions, ...options };
    }

    if (currencyCode === undefined) {
        currencyCode = Currency.EUR;
    }

    const parsedValue = parseFloat(value as unknown as string);
    if (isNaN(parsedValue)) {
        if (currencyOptions.alwaysWithCurrency && currencyOptions.defaultValue !== null) {
            const defaultValue = parseFloat(currencyOptions.defaultValue as unknown as string);
            return formatValue(defaultValue, currencyCode, currencyOptions);
        }

        return String(currencyOptions.defaultValue);
    }

    return formatValue(parsedValue, currencyCode, currencyOptions);
}

function formatValue(value: string | number, currencyCode: string, currencyOptions: ICurrencyOptions): string {
    let formattedValue = value.toLocaleString('en-US', {
        minimumFractionDigits: currencyOptions.addZero ? 2 : 0,
        maximumFractionDigits: 2,
        style: currencyOptions.hideSymbol ? 'decimal' : 'currency',
        currency: currencyCode,
        currencyDisplay: currencyOptions.displayType as Intl.NumberFormatOptionsCurrencyDisplay
    });

    // if currency code will be displayed - place currency code after number
    if (currencyOptions.displayType === CurrencyDisplayType.CODE || formattedValue.indexOf(currencyCode) !== -1) {
        formattedValue = formattedValue.replace(currencyCode, '').replace('\xa0', '').concat('\xa0' + currencyCode);
    }

    return formattedValue;
}

export default withCurrency;
