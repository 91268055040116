/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminRakecollectAffiliateSourceReportResource } from '../AfClApiModels';
// @ts-ignore
import { AdminRakecollectGetAffiliateSources200Response } from '../AfClApiModels';
/**
 * AdminRakeCollectAffiliateSourcesApi - axios parameter creator
 * @export
 */
export const AdminRakeCollectAffiliateSourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the reported data for a given campaign affiliate source in a specific day
         * @param {number} affiliateSource Numeric ID of the Redirect Link to find
         * @param {string} date date format Y-m-d
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRakecollectGetAffiliateSourceReport: async (affiliateSource: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateSource' is not null or undefined
            assertParamExists('adminRakecollectGetAffiliateSourceReport', 'affiliateSource', affiliateSource)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('adminRakecollectGetAffiliateSourceReport', 'date', date)
            const localVarPath = `/v1/admin/rakecollect/affiliate-sources/{affiliateSource}/report`
                .replace(`{${"affiliateSource"}}`, encodeURIComponent(String(affiliateSource)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Affiliate Sources for the RakeCollect endpoint
         * @param {Array<number>} [affiliateAccountIds] 
         * @param {number} [subscriptionCampaignId] 
         * @param {string} [brandName] 
         * @param {string} [campaignId] 
         * @param {string} [campaignName] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRakecollectGetAffiliateSources: async (affiliateAccountIds?: Array<number>, subscriptionCampaignId?: number, brandName?: string, campaignId?: string, campaignName?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/rakecollect/affiliate-sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (affiliateAccountIds) {
                localVarQueryParameter['affiliate_account_ids[]'] = affiliateAccountIds;
            }

            if (subscriptionCampaignId !== undefined) {
                localVarQueryParameter['subscription_campaign_id'] = subscriptionCampaignId;
            }

            if (brandName !== undefined) {
                localVarQueryParameter['brand_name'] = brandName;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign_id'] = campaignId;
            }

            if (campaignName !== undefined) {
                localVarQueryParameter['campaign_name'] = campaignName;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminRakeCollectAffiliateSourcesApi - functional programming interface
 * @export
 */
export const AdminRakeCollectAffiliateSourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminRakeCollectAffiliateSourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the reported data for a given campaign affiliate source in a specific day
         * @param {number} affiliateSource Numeric ID of the Redirect Link to find
         * @param {string} date date format Y-m-d
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRakecollectGetAffiliateSourceReport(affiliateSource: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminRakecollectAffiliateSourceReportResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRakecollectGetAffiliateSourceReport(affiliateSource, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Affiliate Sources for the RakeCollect endpoint
         * @param {Array<number>} [affiliateAccountIds] 
         * @param {number} [subscriptionCampaignId] 
         * @param {string} [brandName] 
         * @param {string} [campaignId] 
         * @param {string} [campaignName] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRakecollectGetAffiliateSources(affiliateAccountIds?: Array<number>, subscriptionCampaignId?: number, brandName?: string, campaignId?: string, campaignName?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminRakecollectGetAffiliateSources200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRakecollectGetAffiliateSources(affiliateAccountIds, subscriptionCampaignId, brandName, campaignId, campaignName, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminRakeCollectAffiliateSourcesApi - factory interface
 * @export
 */
export const AdminRakeCollectAffiliateSourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminRakeCollectAffiliateSourcesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the reported data for a given campaign affiliate source in a specific day
         * @param {AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourceReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRakecollectGetAffiliateSourceReport(requestParameters: AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourceReportRequest, options?: AxiosRequestConfig): AxiosPromise<AdminRakecollectAffiliateSourceReportResource> {
            return localVarFp.adminRakecollectGetAffiliateSourceReport(requestParameters.affiliateSource, requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Affiliate Sources for the RakeCollect endpoint
         * @param {AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourcesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRakecollectGetAffiliateSources(requestParameters: AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourcesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminRakecollectGetAffiliateSources200Response> {
            return localVarFp.adminRakecollectGetAffiliateSources(requestParameters.affiliateAccountIds, requestParameters.subscriptionCampaignId, requestParameters.brandName, requestParameters.campaignId, requestParameters.campaignName, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminRakecollectGetAffiliateSourceReport operation in AdminRakeCollectAffiliateSourcesApi.
 * @export
 * @interface AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourceReportRequest
 */
export interface AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourceReportRequest {
    /**
     * Numeric ID of the Redirect Link to find
     * @type {number}
     * @memberof AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourceReport
     */
    readonly affiliateSource: number

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourceReport
     */
    readonly date: string
}

/**
 * Request parameters for adminRakecollectGetAffiliateSources operation in AdminRakeCollectAffiliateSourcesApi.
 * @export
 * @interface AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourcesRequest
 */
export interface AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourcesRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSources
     */
    readonly affiliateAccountIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSources
     */
    readonly subscriptionCampaignId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSources
     */
    readonly brandName?: string

    /**
     * 
     * @type {string}
     * @memberof AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSources
     */
    readonly campaignId?: string

    /**
     * 
     * @type {string}
     * @memberof AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSources
     */
    readonly campaignName?: string

    /**
     * 
     * @type {number}
     * @memberof AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSources
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSources
     */
    readonly perPage?: number
}

/**
 * AdminRakeCollectAffiliateSourcesApi - object-oriented interface
 * @export
 * @class AdminRakeCollectAffiliateSourcesApi
 * @extends {BaseAPI}
 */
export class AdminRakeCollectAffiliateSourcesApi extends BaseAPI {
    /**
     * 
     * @summary Get the reported data for a given campaign affiliate source in a specific day
     * @param {AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourceReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRakeCollectAffiliateSourcesApi
     */
    public adminRakecollectGetAffiliateSourceReport(requestParameters: AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourceReportRequest, options?: AxiosRequestConfig) {
        return AdminRakeCollectAffiliateSourcesApiFp(this.configuration).adminRakecollectGetAffiliateSourceReport(requestParameters.affiliateSource, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Affiliate Sources for the RakeCollect endpoint
     * @param {AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRakeCollectAffiliateSourcesApi
     */
    public adminRakecollectGetAffiliateSources(requestParameters: AdminRakeCollectAffiliateSourcesApiAdminRakecollectGetAffiliateSourcesRequest = {}, options?: AxiosRequestConfig) {
        return AdminRakeCollectAffiliateSourcesApiFp(this.configuration).adminRakecollectGetAffiliateSources(requestParameters.affiliateAccountIds, requestParameters.subscriptionCampaignId, requestParameters.brandName, requestParameters.campaignId, requestParameters.campaignName, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
