/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminActivityResource } from '../AfClApiModels';
// @ts-ignore
import { AdminCheckAffiliateAccountHealth200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminGetAdvertisersBasicPair200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminGetAffiliateAccountOTP200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminGetAffiliateAccountPassword200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminGetAffiliateAccounts200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminStoreAffiliateAccountRequest } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateAffiliateAccountRequest } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateAffiliateAccountUsersRequest } from '../AfClApiModels';
// @ts-ignore
import { AffiliateAccountResource } from '../AfClApiModels';
// @ts-ignore
import { UserShortResource } from '../AfClApiModels';
/**
 * AdminAffiliateAccountsApi - axios parameter creator
 * @export
 */
export const AdminAffiliateAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check Affiliate Account Health Status
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckAffiliateAccountHealth: async (affiliateAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateAccountId' is not null or undefined
            assertParamExists('adminCheckAffiliateAccountHealth', 'affiliateAccountId', affiliateAccountId)
            const localVarPath = `/v1/admin/affiliate-accounts/{affiliateAccountId}/check-health-status`
                .replace(`{${"affiliateAccountId"}}`, encodeURIComponent(String(affiliateAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Store Affiliate Account
         * @param {AdminStoreAffiliateAccountRequest} [adminStoreAffiliateAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAffiliateAccount: async (adminStoreAffiliateAccountRequest?: AdminStoreAffiliateAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/affiliate-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStoreAffiliateAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export Affiliate Accounts
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'} [status] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {string} [affiliateAccountId] 
         * @param {string} [campaignCode] 
         * @param {number} [advertiserId] 
         * @param {number} [corporationId] 
         * @param {string} [affiliateSystemAccountId] 
         * @param {string} [createdFrom] 
         * @param {string} [createdTo] 
         * @param {'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA'} [healthStatus] 
         * @param {boolean} [hasPaymentDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportAffiliateAccounts: async (username?: string, email?: string, status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY', brandIds?: Array<number>, programIds?: Array<number>, siteIds?: Array<number>, affiliateAccountId?: string, campaignCode?: string, advertiserId?: number, corporationId?: number, affiliateSystemAccountId?: string, createdFrom?: string, createdTo?: string, healthStatus?: 'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA', hasPaymentDetails?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/affiliate-accounts/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (programIds) {
                localVarQueryParameter['program_ids[]'] = programIds;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (affiliateAccountId !== undefined) {
                localVarQueryParameter['affiliate_account_id'] = affiliateAccountId;
            }

            if (campaignCode !== undefined) {
                localVarQueryParameter['campaign_code'] = campaignCode;
            }

            if (advertiserId !== undefined) {
                localVarQueryParameter['advertiser_id'] = advertiserId;
            }

            if (corporationId !== undefined) {
                localVarQueryParameter['corporation_id'] = corporationId;
            }

            if (affiliateSystemAccountId !== undefined) {
                localVarQueryParameter['affiliate_system_account_id'] = affiliateSystemAccountId;
            }

            if (createdFrom !== undefined) {
                localVarQueryParameter['created_from'] = (createdFrom as any instanceof Date) ?
                    (createdFrom as any).toISOString().substr(0,10) :
                    createdFrom;
            }

            if (createdTo !== undefined) {
                localVarQueryParameter['created_to'] = (createdTo as any instanceof Date) ?
                    (createdTo as any).toISOString().substr(0,10) :
                    createdTo;
            }

            if (healthStatus !== undefined) {
                localVarQueryParameter['health_status'] = healthStatus;
            }

            if (hasPaymentDetails !== undefined) {
                localVarQueryParameter['has_payment_details'] = hasPaymentDetails;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details about an affiliate account
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccount: async (affiliateAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateAccountId' is not null or undefined
            assertParamExists('adminGetAffiliateAccount', 'affiliateAccountId', affiliateAccountId)
            const localVarPath = `/v1/admin/affiliate-accounts/{affiliateAccountId}`
                .replace(`{${"affiliateAccountId"}}`, encodeURIComponent(String(affiliateAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get affiliate account OTP
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccountOTP: async (affiliateAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateAccountId' is not null or undefined
            assertParamExists('adminGetAffiliateAccountOTP', 'affiliateAccountId', affiliateAccountId)
            const localVarPath = `/v1/admin/affiliate-accounts/{affiliateAccountId}/otp`
                .replace(`{${"affiliateAccountId"}}`, encodeURIComponent(String(affiliateAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get affiliate account password
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account
         * @param {'VIEW' | 'COPIED'} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccountPassword: async (affiliateAccountId: number, action?: 'VIEW' | 'COPIED', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateAccountId' is not null or undefined
            assertParamExists('adminGetAffiliateAccountPassword', 'affiliateAccountId', affiliateAccountId)
            const localVarPath = `/v1/admin/affiliate-accounts/{affiliateAccountId}/password`
                .replace(`{${"affiliateAccountId"}}`, encodeURIComponent(String(affiliateAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Affiliate Account Users
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccountUsers: async (affiliateAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateAccountId' is not null or undefined
            assertParamExists('adminGetAffiliateAccountUsers', 'affiliateAccountId', affiliateAccountId)
            const localVarPath = `/v1/admin/affiliate-accounts/{affiliateAccountId}/users`
                .replace(`{${"affiliateAccountId"}}`, encodeURIComponent(String(affiliateAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Affiliate Accounts
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'} [status] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {string} [affiliateAccountId] 
         * @param {number} [advertiserId] 
         * @param {number} [corporationId] 
         * @param {string} [affiliateSystemAccountId] 
         * @param {string} [campaignCode] 
         * @param {string} [createdFrom] 
         * @param {string} [createdTo] 
         * @param {'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA'} [healthStatus] 
         * @param {boolean} [hasPaymentDetails] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccounts: async (username?: string, email?: string, status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY', brandIds?: Array<number>, programIds?: Array<number>, siteIds?: Array<number>, affiliateAccountId?: string, advertiserId?: number, corporationId?: number, affiliateSystemAccountId?: string, campaignCode?: string, createdFrom?: string, createdTo?: string, healthStatus?: 'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA', hasPaymentDetails?: boolean, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/affiliate-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (programIds) {
                localVarQueryParameter['program_ids[]'] = programIds;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (affiliateAccountId !== undefined) {
                localVarQueryParameter['affiliate_account_id'] = affiliateAccountId;
            }

            if (advertiserId !== undefined) {
                localVarQueryParameter['advertiser_id'] = advertiserId;
            }

            if (corporationId !== undefined) {
                localVarQueryParameter['corporation_id'] = corporationId;
            }

            if (affiliateSystemAccountId !== undefined) {
                localVarQueryParameter['affiliate_system_account_id'] = affiliateSystemAccountId;
            }

            if (campaignCode !== undefined) {
                localVarQueryParameter['campaign_code'] = campaignCode;
            }

            if (createdFrom !== undefined) {
                localVarQueryParameter['created_from'] = (createdFrom as any instanceof Date) ?
                    (createdFrom as any).toISOString().substr(0,10) :
                    createdFrom;
            }

            if (createdTo !== undefined) {
                localVarQueryParameter['created_to'] = (createdTo as any instanceof Date) ?
                    (createdTo as any).toISOString().substr(0,10) :
                    createdTo;
            }

            if (healthStatus !== undefined) {
                localVarQueryParameter['health_status'] = healthStatus;
            }

            if (hasPaymentDetails !== undefined) {
                localVarQueryParameter['has_payment_details'] = hasPaymentDetails;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Affiliate Accounts Basic Pair
         * @param {string} [username] 
         * @param {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'} [status] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {number} [affiliateAccountId] 
         * @param {number} [advertiserId] 
         * @param {number} [corporationId] 
         * @param {string} [affiliateSystemAccountId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccountsBasicPair: async (username?: string, status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY', brandIds?: Array<number>, programIds?: Array<number>, siteIds?: Array<number>, affiliateAccountId?: number, advertiserId?: number, corporationId?: number, affiliateSystemAccountId?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/affiliate-accounts-basic-pair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (programIds) {
                localVarQueryParameter['program_ids[]'] = programIds;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (affiliateAccountId !== undefined) {
                localVarQueryParameter['affiliate_account_id'] = affiliateAccountId;
            }

            if (advertiserId !== undefined) {
                localVarQueryParameter['advertiser_id'] = advertiserId;
            }

            if (corporationId !== undefined) {
                localVarQueryParameter['corporation_id'] = corporationId;
            }

            if (affiliateSystemAccountId !== undefined) {
                localVarQueryParameter['affiliate_system_account_id'] = affiliateSystemAccountId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Affiliate Account
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account to update
         * @param {AdminUpdateAffiliateAccountRequest} [adminUpdateAffiliateAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAffiliateAccount: async (affiliateAccountId: number, adminUpdateAffiliateAccountRequest?: AdminUpdateAffiliateAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateAccountId' is not null or undefined
            assertParamExists('adminUpdateAffiliateAccount', 'affiliateAccountId', affiliateAccountId)
            const localVarPath = `/v1/admin/affiliate-accounts/{affiliateAccountId}`
                .replace(`{${"affiliateAccountId"}}`, encodeURIComponent(String(affiliateAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateAffiliateAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Affiliate Account Users
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account to update
         * @param {AdminUpdateAffiliateAccountUsersRequest} [adminUpdateAffiliateAccountUsersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAffiliateAccountUsers: async (affiliateAccountId: number, adminUpdateAffiliateAccountUsersRequest?: AdminUpdateAffiliateAccountUsersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateAccountId' is not null or undefined
            assertParamExists('adminUpdateAffiliateAccountUsers', 'affiliateAccountId', affiliateAccountId)
            const localVarPath = `/v1/admin/affiliate-accounts/{affiliateAccountId}/users`
                .replace(`{${"affiliateAccountId"}}`, encodeURIComponent(String(affiliateAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateAffiliateAccountUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Affiliate Account audit trail
         * @param {number} affiliateAccountId Numeric ID of the affiliate account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliateAccountActivities: async (affiliateAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateAccountId' is not null or undefined
            assertParamExists('getAffiliateAccountActivities', 'affiliateAccountId', affiliateAccountId)
            const localVarPath = `/v1/admin/affiliate-accounts/{affiliateAccountId}/activities`
                .replace(`{${"affiliateAccountId"}}`, encodeURIComponent(String(affiliateAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAffiliateAccountsApi - functional programming interface
 * @export
 */
export const AdminAffiliateAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAffiliateAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check Affiliate Account Health Status
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCheckAffiliateAccountHealth(affiliateAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCheckAffiliateAccountHealth200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCheckAffiliateAccountHealth(affiliateAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Store Affiliate Account
         * @param {AdminStoreAffiliateAccountRequest} [adminStoreAffiliateAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateAffiliateAccount(adminStoreAffiliateAccountRequest?: AdminStoreAffiliateAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliateAccountResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateAffiliateAccount(adminStoreAffiliateAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export Affiliate Accounts
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'} [status] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {string} [affiliateAccountId] 
         * @param {string} [campaignCode] 
         * @param {number} [advertiserId] 
         * @param {number} [corporationId] 
         * @param {string} [affiliateSystemAccountId] 
         * @param {string} [createdFrom] 
         * @param {string} [createdTo] 
         * @param {'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA'} [healthStatus] 
         * @param {boolean} [hasPaymentDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminExportAffiliateAccounts(username?: string, email?: string, status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY', brandIds?: Array<number>, programIds?: Array<number>, siteIds?: Array<number>, affiliateAccountId?: string, campaignCode?: string, advertiserId?: number, corporationId?: number, affiliateSystemAccountId?: string, createdFrom?: string, createdTo?: string, healthStatus?: 'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA', hasPaymentDetails?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminExportAffiliateAccounts(username, email, status, brandIds, programIds, siteIds, affiliateAccountId, campaignCode, advertiserId, corporationId, affiliateSystemAccountId, createdFrom, createdTo, healthStatus, hasPaymentDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details about an affiliate account
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAffiliateAccount(affiliateAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliateAccountResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAffiliateAccount(affiliateAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get affiliate account OTP
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAffiliateAccountOTP(affiliateAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAffiliateAccountOTP200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAffiliateAccountOTP(affiliateAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get affiliate account password
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account
         * @param {'VIEW' | 'COPIED'} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAffiliateAccountPassword(affiliateAccountId: number, action?: 'VIEW' | 'COPIED', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAffiliateAccountPassword200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAffiliateAccountPassword(affiliateAccountId, action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Affiliate Account Users
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAffiliateAccountUsers(affiliateAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserShortResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAffiliateAccountUsers(affiliateAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Affiliate Accounts
         * @param {string} [username] 
         * @param {string} [email] 
         * @param {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'} [status] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {string} [affiliateAccountId] 
         * @param {number} [advertiserId] 
         * @param {number} [corporationId] 
         * @param {string} [affiliateSystemAccountId] 
         * @param {string} [campaignCode] 
         * @param {string} [createdFrom] 
         * @param {string} [createdTo] 
         * @param {'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA'} [healthStatus] 
         * @param {boolean} [hasPaymentDetails] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAffiliateAccounts(username?: string, email?: string, status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY', brandIds?: Array<number>, programIds?: Array<number>, siteIds?: Array<number>, affiliateAccountId?: string, advertiserId?: number, corporationId?: number, affiliateSystemAccountId?: string, campaignCode?: string, createdFrom?: string, createdTo?: string, healthStatus?: 'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA', hasPaymentDetails?: boolean, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAffiliateAccounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAffiliateAccounts(username, email, status, brandIds, programIds, siteIds, affiliateAccountId, advertiserId, corporationId, affiliateSystemAccountId, campaignCode, createdFrom, createdTo, healthStatus, hasPaymentDetails, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Affiliate Accounts Basic Pair
         * @param {string} [username] 
         * @param {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'} [status] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {number} [affiliateAccountId] 
         * @param {number} [advertiserId] 
         * @param {number} [corporationId] 
         * @param {string} [affiliateSystemAccountId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAffiliateAccountsBasicPair(username?: string, status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY', brandIds?: Array<number>, programIds?: Array<number>, siteIds?: Array<number>, affiliateAccountId?: number, advertiserId?: number, corporationId?: number, affiliateSystemAccountId?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAdvertisersBasicPair200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAffiliateAccountsBasicPair(username, status, brandIds, programIds, siteIds, affiliateAccountId, advertiserId, corporationId, affiliateSystemAccountId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Affiliate Account
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account to update
         * @param {AdminUpdateAffiliateAccountRequest} [adminUpdateAffiliateAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateAffiliateAccount(affiliateAccountId: number, adminUpdateAffiliateAccountRequest?: AdminUpdateAffiliateAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliateAccountResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateAffiliateAccount(affiliateAccountId, adminUpdateAffiliateAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Affiliate Account Users
         * @param {number} affiliateAccountId Numeric ID of the Affiliate Account to update
         * @param {AdminUpdateAffiliateAccountUsersRequest} [adminUpdateAffiliateAccountUsersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateAffiliateAccountUsers(affiliateAccountId: number, adminUpdateAffiliateAccountUsersRequest?: AdminUpdateAffiliateAccountUsersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserShortResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateAffiliateAccountUsers(affiliateAccountId, adminUpdateAffiliateAccountUsersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Affiliate Account audit trail
         * @param {number} affiliateAccountId Numeric ID of the affiliate account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAffiliateAccountActivities(affiliateAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminActivityResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAffiliateAccountActivities(affiliateAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAffiliateAccountsApi - factory interface
 * @export
 */
export const AdminAffiliateAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAffiliateAccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Check Affiliate Account Health Status
         * @param {AdminAffiliateAccountsApiAdminCheckAffiliateAccountHealthRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCheckAffiliateAccountHealth(requestParameters: AdminAffiliateAccountsApiAdminCheckAffiliateAccountHealthRequest, options?: AxiosRequestConfig): AxiosPromise<AdminCheckAffiliateAccountHealth200Response> {
            return localVarFp.adminCheckAffiliateAccountHealth(requestParameters.affiliateAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Store Affiliate Account
         * @param {AdminAffiliateAccountsApiAdminCreateAffiliateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAffiliateAccount(requestParameters: AdminAffiliateAccountsApiAdminCreateAffiliateAccountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AffiliateAccountResource> {
            return localVarFp.adminCreateAffiliateAccount(requestParameters.adminStoreAffiliateAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export Affiliate Accounts
         * @param {AdminAffiliateAccountsApiAdminExportAffiliateAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminExportAffiliateAccounts(requestParameters: AdminAffiliateAccountsApiAdminExportAffiliateAccountsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.adminExportAffiliateAccounts(requestParameters.username, requestParameters.email, requestParameters.status, requestParameters.brandIds, requestParameters.programIds, requestParameters.siteIds, requestParameters.affiliateAccountId, requestParameters.campaignCode, requestParameters.advertiserId, requestParameters.corporationId, requestParameters.affiliateSystemAccountId, requestParameters.createdFrom, requestParameters.createdTo, requestParameters.healthStatus, requestParameters.hasPaymentDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details about an affiliate account
         * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccount(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountRequest, options?: AxiosRequestConfig): AxiosPromise<AffiliateAccountResource> {
            return localVarFp.adminGetAffiliateAccount(requestParameters.affiliateAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get affiliate account OTP
         * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountOTPRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccountOTP(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountOTPRequest, options?: AxiosRequestConfig): AxiosPromise<AdminGetAffiliateAccountOTP200Response> {
            return localVarFp.adminGetAffiliateAccountOTP(requestParameters.affiliateAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get affiliate account password
         * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccountPassword(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountPasswordRequest, options?: AxiosRequestConfig): AxiosPromise<AdminGetAffiliateAccountPassword200Response> {
            return localVarFp.adminGetAffiliateAccountPassword(requestParameters.affiliateAccountId, requestParameters.action, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Affiliate Account Users
         * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccountUsers(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountUsersRequest, options?: AxiosRequestConfig): AxiosPromise<Array<UserShortResource>> {
            return localVarFp.adminGetAffiliateAccountUsers(requestParameters.affiliateAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Affiliate Accounts
         * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccounts(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetAffiliateAccounts200Response> {
            return localVarFp.adminGetAffiliateAccounts(requestParameters.username, requestParameters.email, requestParameters.status, requestParameters.brandIds, requestParameters.programIds, requestParameters.siteIds, requestParameters.affiliateAccountId, requestParameters.advertiserId, requestParameters.corporationId, requestParameters.affiliateSystemAccountId, requestParameters.campaignCode, requestParameters.createdFrom, requestParameters.createdTo, requestParameters.healthStatus, requestParameters.hasPaymentDetails, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Affiliate Accounts Basic Pair
         * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPairRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAffiliateAccountsBasicPair(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPairRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetAdvertisersBasicPair200Response> {
            return localVarFp.adminGetAffiliateAccountsBasicPair(requestParameters.username, requestParameters.status, requestParameters.brandIds, requestParameters.programIds, requestParameters.siteIds, requestParameters.affiliateAccountId, requestParameters.advertiserId, requestParameters.corporationId, requestParameters.affiliateSystemAccountId, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Affiliate Account
         * @param {AdminAffiliateAccountsApiAdminUpdateAffiliateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAffiliateAccount(requestParameters: AdminAffiliateAccountsApiAdminUpdateAffiliateAccountRequest, options?: AxiosRequestConfig): AxiosPromise<AffiliateAccountResource> {
            return localVarFp.adminUpdateAffiliateAccount(requestParameters.affiliateAccountId, requestParameters.adminUpdateAffiliateAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Affiliate Account Users
         * @param {AdminAffiliateAccountsApiAdminUpdateAffiliateAccountUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateAffiliateAccountUsers(requestParameters: AdminAffiliateAccountsApiAdminUpdateAffiliateAccountUsersRequest, options?: AxiosRequestConfig): AxiosPromise<Array<UserShortResource>> {
            return localVarFp.adminUpdateAffiliateAccountUsers(requestParameters.affiliateAccountId, requestParameters.adminUpdateAffiliateAccountUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Affiliate Account audit trail
         * @param {AdminAffiliateAccountsApiGetAffiliateAccountActivitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliateAccountActivities(requestParameters: AdminAffiliateAccountsApiGetAffiliateAccountActivitiesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AdminActivityResource>> {
            return localVarFp.getAffiliateAccountActivities(requestParameters.affiliateAccountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminCheckAffiliateAccountHealth operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiAdminCheckAffiliateAccountHealthRequest
 */
export interface AdminAffiliateAccountsApiAdminCheckAffiliateAccountHealthRequest {
    /**
     * Numeric ID of the Affiliate Account
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminCheckAffiliateAccountHealth
     */
    readonly affiliateAccountId: number
}

/**
 * Request parameters for adminCreateAffiliateAccount operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiAdminCreateAffiliateAccountRequest
 */
export interface AdminAffiliateAccountsApiAdminCreateAffiliateAccountRequest {
    /**
     * 
     * @type {AdminStoreAffiliateAccountRequest}
     * @memberof AdminAffiliateAccountsApiAdminCreateAffiliateAccount
     */
    readonly adminStoreAffiliateAccountRequest?: AdminStoreAffiliateAccountRequest
}

/**
 * Request parameters for adminExportAffiliateAccounts operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiAdminExportAffiliateAccountsRequest
 */
export interface AdminAffiliateAccountsApiAdminExportAffiliateAccountsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly email?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly programIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly affiliateAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly campaignCode?: string

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly advertiserId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly corporationId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly affiliateSystemAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly createdFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly createdTo?: string

    /**
     * 
     * @type {'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA'}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly healthStatus?: 'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA'

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateAccountsApiAdminExportAffiliateAccounts
     */
    readonly hasPaymentDetails?: boolean
}

/**
 * Request parameters for adminGetAffiliateAccount operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiAdminGetAffiliateAccountRequest
 */
export interface AdminAffiliateAccountsApiAdminGetAffiliateAccountRequest {
    /**
     * Numeric ID of the Affiliate Account to find
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccount
     */
    readonly affiliateAccountId: number
}

/**
 * Request parameters for adminGetAffiliateAccountOTP operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiAdminGetAffiliateAccountOTPRequest
 */
export interface AdminAffiliateAccountsApiAdminGetAffiliateAccountOTPRequest {
    /**
     * Numeric ID of the Affiliate Account
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountOTP
     */
    readonly affiliateAccountId: number
}

/**
 * Request parameters for adminGetAffiliateAccountPassword operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiAdminGetAffiliateAccountPasswordRequest
 */
export interface AdminAffiliateAccountsApiAdminGetAffiliateAccountPasswordRequest {
    /**
     * Numeric ID of the Affiliate Account
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountPassword
     */
    readonly affiliateAccountId: number

    /**
     * 
     * @type {'VIEW' | 'COPIED'}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountPassword
     */
    readonly action?: 'VIEW' | 'COPIED'
}

/**
 * Request parameters for adminGetAffiliateAccountUsers operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiAdminGetAffiliateAccountUsersRequest
 */
export interface AdminAffiliateAccountsApiAdminGetAffiliateAccountUsersRequest {
    /**
     * Numeric ID of the Affiliate Account to find
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountUsers
     */
    readonly affiliateAccountId: number
}

/**
 * Request parameters for adminGetAffiliateAccounts operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiAdminGetAffiliateAccountsRequest
 */
export interface AdminAffiliateAccountsApiAdminGetAffiliateAccountsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly email?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly programIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly affiliateAccountId?: string

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly advertiserId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly corporationId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly affiliateSystemAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly campaignCode?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly createdFrom?: string

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly createdTo?: string

    /**
     * 
     * @type {'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA'}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly healthStatus?: 'UNKNWN' | 'OK' | 'INVCRD' | 'LOCKED' | 'MODAL' | 'TIMEOUT' | 'CAPTCHA'

    /**
     * 
     * @type {boolean}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly hasPaymentDetails?: boolean

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccounts
     */
    readonly perPage?: number
}

/**
 * Request parameters for adminGetAffiliateAccountsBasicPair operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPairRequest
 */
export interface AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPairRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPair
     */
    readonly username?: string

    /**
     * 
     * @type {'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPair
     */
    readonly status?: 'ACTIVE' | 'INACTIVE' | 'CLOSED' | 'PENDING' | 'DECLINED' | 'LOCKED' | 'MIGRATED' | 'DELETED' | 'WRONG_LOGIN' | 'TO_BE_CLOSED' | 'DATA_ONLY'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPair
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPair
     */
    readonly programIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPair
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPair
     */
    readonly affiliateAccountId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPair
     */
    readonly advertiserId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPair
     */
    readonly corporationId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPair
     */
    readonly affiliateSystemAccountId?: string

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPair
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPair
     */
    readonly perPage?: number
}

/**
 * Request parameters for adminUpdateAffiliateAccount operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiAdminUpdateAffiliateAccountRequest
 */
export interface AdminAffiliateAccountsApiAdminUpdateAffiliateAccountRequest {
    /**
     * Numeric ID of the Affiliate Account to update
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminUpdateAffiliateAccount
     */
    readonly affiliateAccountId: number

    /**
     * 
     * @type {AdminUpdateAffiliateAccountRequest}
     * @memberof AdminAffiliateAccountsApiAdminUpdateAffiliateAccount
     */
    readonly adminUpdateAffiliateAccountRequest?: AdminUpdateAffiliateAccountRequest
}

/**
 * Request parameters for adminUpdateAffiliateAccountUsers operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiAdminUpdateAffiliateAccountUsersRequest
 */
export interface AdminAffiliateAccountsApiAdminUpdateAffiliateAccountUsersRequest {
    /**
     * Numeric ID of the Affiliate Account to update
     * @type {number}
     * @memberof AdminAffiliateAccountsApiAdminUpdateAffiliateAccountUsers
     */
    readonly affiliateAccountId: number

    /**
     * 
     * @type {AdminUpdateAffiliateAccountUsersRequest}
     * @memberof AdminAffiliateAccountsApiAdminUpdateAffiliateAccountUsers
     */
    readonly adminUpdateAffiliateAccountUsersRequest?: AdminUpdateAffiliateAccountUsersRequest
}

/**
 * Request parameters for getAffiliateAccountActivities operation in AdminAffiliateAccountsApi.
 * @export
 * @interface AdminAffiliateAccountsApiGetAffiliateAccountActivitiesRequest
 */
export interface AdminAffiliateAccountsApiGetAffiliateAccountActivitiesRequest {
    /**
     * Numeric ID of the affiliate account
     * @type {number}
     * @memberof AdminAffiliateAccountsApiGetAffiliateAccountActivities
     */
    readonly affiliateAccountId: number
}

/**
 * AdminAffiliateAccountsApi - object-oriented interface
 * @export
 * @class AdminAffiliateAccountsApi
 * @extends {BaseAPI}
 */
export class AdminAffiliateAccountsApi extends BaseAPI {
    /**
     * 
     * @summary Check Affiliate Account Health Status
     * @param {AdminAffiliateAccountsApiAdminCheckAffiliateAccountHealthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public adminCheckAffiliateAccountHealth(requestParameters: AdminAffiliateAccountsApiAdminCheckAffiliateAccountHealthRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).adminCheckAffiliateAccountHealth(requestParameters.affiliateAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Store Affiliate Account
     * @param {AdminAffiliateAccountsApiAdminCreateAffiliateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public adminCreateAffiliateAccount(requestParameters: AdminAffiliateAccountsApiAdminCreateAffiliateAccountRequest = {}, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).adminCreateAffiliateAccount(requestParameters.adminStoreAffiliateAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export Affiliate Accounts
     * @param {AdminAffiliateAccountsApiAdminExportAffiliateAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public adminExportAffiliateAccounts(requestParameters: AdminAffiliateAccountsApiAdminExportAffiliateAccountsRequest = {}, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).adminExportAffiliateAccounts(requestParameters.username, requestParameters.email, requestParameters.status, requestParameters.brandIds, requestParameters.programIds, requestParameters.siteIds, requestParameters.affiliateAccountId, requestParameters.campaignCode, requestParameters.advertiserId, requestParameters.corporationId, requestParameters.affiliateSystemAccountId, requestParameters.createdFrom, requestParameters.createdTo, requestParameters.healthStatus, requestParameters.hasPaymentDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details about an affiliate account
     * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public adminGetAffiliateAccount(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).adminGetAffiliateAccount(requestParameters.affiliateAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get affiliate account OTP
     * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountOTPRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public adminGetAffiliateAccountOTP(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountOTPRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).adminGetAffiliateAccountOTP(requestParameters.affiliateAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get affiliate account password
     * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public adminGetAffiliateAccountPassword(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountPasswordRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).adminGetAffiliateAccountPassword(requestParameters.affiliateAccountId, requestParameters.action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Affiliate Account Users
     * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public adminGetAffiliateAccountUsers(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountUsersRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).adminGetAffiliateAccountUsers(requestParameters.affiliateAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Affiliate Accounts
     * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public adminGetAffiliateAccounts(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountsRequest = {}, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).adminGetAffiliateAccounts(requestParameters.username, requestParameters.email, requestParameters.status, requestParameters.brandIds, requestParameters.programIds, requestParameters.siteIds, requestParameters.affiliateAccountId, requestParameters.advertiserId, requestParameters.corporationId, requestParameters.affiliateSystemAccountId, requestParameters.campaignCode, requestParameters.createdFrom, requestParameters.createdTo, requestParameters.healthStatus, requestParameters.hasPaymentDetails, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Affiliate Accounts Basic Pair
     * @param {AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPairRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public adminGetAffiliateAccountsBasicPair(requestParameters: AdminAffiliateAccountsApiAdminGetAffiliateAccountsBasicPairRequest = {}, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).adminGetAffiliateAccountsBasicPair(requestParameters.username, requestParameters.status, requestParameters.brandIds, requestParameters.programIds, requestParameters.siteIds, requestParameters.affiliateAccountId, requestParameters.advertiserId, requestParameters.corporationId, requestParameters.affiliateSystemAccountId, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Affiliate Account
     * @param {AdminAffiliateAccountsApiAdminUpdateAffiliateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public adminUpdateAffiliateAccount(requestParameters: AdminAffiliateAccountsApiAdminUpdateAffiliateAccountRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).adminUpdateAffiliateAccount(requestParameters.affiliateAccountId, requestParameters.adminUpdateAffiliateAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Affiliate Account Users
     * @param {AdminAffiliateAccountsApiAdminUpdateAffiliateAccountUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public adminUpdateAffiliateAccountUsers(requestParameters: AdminAffiliateAccountsApiAdminUpdateAffiliateAccountUsersRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).adminUpdateAffiliateAccountUsers(requestParameters.affiliateAccountId, requestParameters.adminUpdateAffiliateAccountUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Affiliate Account audit trail
     * @param {AdminAffiliateAccountsApiGetAffiliateAccountActivitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAffiliateAccountsApi
     */
    public getAffiliateAccountActivities(requestParameters: AdminAffiliateAccountsApiGetAffiliateAccountActivitiesRequest, options?: AxiosRequestConfig) {
        return AdminAffiliateAccountsApiFp(this.configuration).getAffiliateAccountActivities(requestParameters.affiliateAccountId, options).then((request) => request(this.axios, this.basePath));
    }
}
